html {
  scroll-behavior: smooth !important;

}
html::before{
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(./bg-1.webp) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
body {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  backdrop-filter: blur(70px);
  -webkit-backdrop-filter: blur(70px);
  
}
body::-webkit-scrollbar {
  display: none !important;
}

#root {
  overflow-x: hidden;
}