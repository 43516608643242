.App {
  text-align: center;
  font-family: 'Lato', sans-serif !important;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
  

}
.vtulogo{
  max-height: 125px;
}
.App::-webkit-scrollbar {
  display: none !important;
}

.darkblue {
  color: #1c2c9b
}

.lightblue {
  color: #3469ff
}

.btnback {
  color: #eff2fb
}

.ofxh {
  overflow-x: hidden !important;
}

.table-row {
  padding-left: 50px;
  padding-right: 50px;
}

.profilephoto {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.card {
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  border-color: transparent
}

table {
  background: rgba( 255, 255, 255, 0.4 );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
}

.form-select {
  background-color: transparent;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.form-control {
  background-color: transparent;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.form-control:focus {
  background-color: transparent;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.maps {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
}

.alert {
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
}

.alert-dange {
  background: rgba(251, 81, 81, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-weight: 800;
  font-size: 22px !important;
}

.alert-succes {
  background: rgba(255, 126, 0, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-weight: 800;
  font-size: 22px !important;
}

.alert-dar {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-weight: 800;
  font-size: 22px !important;
}

.alert-warnin {
  background: rgba(166, 81, 251, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-weight: 800;
  font-size: 22px !important;
}

.alert-inf {
  background: rgba(0, 185, 181, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #ffffff;
  font-weight: 800;
  font-size: 22px !important;
}
.alert-dismissible {
  background: rgba( 225, 255, 94, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.15 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  color: #121212;
  font-weight: 600;
  font-size: 16px !important;
}
.Loader-logo {
  margin-top:20vh;
  margin-bottom: 10vh;
  height: 35vh;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Loader-logo {
    animation: Loader-logo-spin infinite 20s linear;
  }
}
.Loader-header {
  background-color: #282c34;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
}

@keyframes Loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading{
  font-size: 24px;
  font-weight: 700;
}
.hide-music{
  display: none;
}
.hide-dance{
  display: none;
}
.hide-literary{
  display: none;
}
.hide-theatre{
  display: none;
}
.hide-fine-arts{
  display: none;
}
.pull-right {
  float: right;
}
.about-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.about-title{
  color: #3469ff;
  font-weight: 700;
}
.slider{
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
}
.txt-justify{
  text-align: justify;
  text-justify: inter-word;
}
.card-about{
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border-color: transparent
}
.countdown{
  font-size: 180px;
  font-weight: 700;
  color: red;
}
.countdown-btn{
  font-size : 80px;
  font-weight: 700;
  height: 300px;
  width: 300px;
  border-radius: 100%;
}
.footer-icon a {
  font-size: 24px !important;
}
.score{
  width: 100%;
  min-height: 100vh;
}